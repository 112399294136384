import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export const Image = ({ src }) => {
  const data = useStaticQuery(graphql`
    query {
      blumImage: file(relativePath: { eq: "blum.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      domusLineImage: file(relativePath: { eq: "domus-line-it.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grassImage: file(relativePath: { eq: "grass-at.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hettichImage: file(relativePath: { eq: "hettich.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kesseboehmerImage: file(relativePath: { eq: "kesseboehmer.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      makmartImage: file(relativePath: { eq: "makmart.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mixalImage: file(relativePath: { eq: "mixal.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ninkaImage: file(relativePath: { eq: "ninka.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      norkpalmImage: file(relativePath: { eq: "norkpalm.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      schuecoImage: file(relativePath: { eq: "schueco.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sigeImage: file(relativePath: { eq: "sige.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      {src === 1 ? (<Img fluid={data.blumImage.childImageSharp.fluid} />) : src === 2 ? (<Img fluid={data.domusLineImage.childImageSharp.fluid} />
      ) : src === 3 ? (<Img fluid={data.grassImage.childImageSharp.fluid} />) : src === 4 ? (<Img fluid={data.hettichImage.childImageSharp.fluid} />
      ) : src === 5 ? (<Img fluid={data.kesseboehmerImage.childImageSharp.fluid} />) : src === 6 ? (<Img fluid={data.makmartImage.childImageSharp.fluid} />
      ) : src === 7 ? (<Img fluid={data.mixalImage.childImageSharp.fluid} />) : src === 8 ? (<Img fluid={data.ninkaImage.childImageSharp.fluid} />
      ) : src === 9 ? (<Img fluid={data.norkpalmImage.childImageSharp.fluid} />) : src === 10 ? (<Img fluid={data.schuecoImage.childImageSharp.fluid} />
      ) : (<Img fluid={data.sigeImage.childImageSharp.fluid} />)}
    </>
  )
}
