import React from "react"
import Container from "react-bootstrap/Container"
import "./features-page-header.scss"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Fade from 'react-reveal/Fade'

const FeaturesSection = () => (
  <StaticQuery query={graphql`
  query {
    manufacturing: file(relativePath: { eq: "manufacturing.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    design: file(
      relativePath: { eq: "design.jpeg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    customfit: file(
      relativePath: { eq: "custom-fit.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`}
    render={data => {
      const manufImage = data.manufacturing.childImageSharp.fluid
      const designImage = data.design.childImageSharp.fluid
      const customfitImage = data.customfit.childImageSharp.fluid
      return (
        <>
          <Container fluid id="features-grid" className="mx-auto px-0">
            <Fade duration={1000} delay={150}>
              <div className="features-flex-container">

                <BackgroundImage className="features-flex-cell manuf-cell" fluid={manufImage}>
                  <div className="features-flex-item border-0">
                    <h2 className="header-item-title text-white h4 text-center">Собственное производство</h2>
                  </div>
                </BackgroundImage>

                <BackgroundImage className="features-flex-cell design-cell" fluid={designImage}>
                  <div className="features-flex-item border-0">
                    <h2 className="header-item-title text-white h4 text-center">Дизайнерские эскизы</h2>

                    <p className="text-white text-center px-2 font-weight-light">
                      В наших салонах дизайнеры составят эскизы вашего будущего
                      проекта и помогут уточнить все необходимые детали
              </p>
                  </div>
                </BackgroundImage>

                <BackgroundImage className="features-flex-cell fit-cell" fluid={customfitImage}>
                  <div className="features-flex-item border-0">
                    <h2 className="header-item-title text-white h4 text-center">Замер, доставка, установка</h2>

                    <p className="text-white text-center px-2 font-weight-light">
                      Всё производим собственными силами
              </p>
                  </div>
                </BackgroundImage>

              </div>
            </Fade>
          </Container>
        </>
      )
    }}
  />
)

export default FeaturesSection

