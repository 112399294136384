import React from "react"
import "./about-us.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Carousel from "../components/carousel-partners"
import Features from "../components/features-page-header"
import Advantages from "../components/adv-section"
import VirtualTourSection from "../components/virtual-tour-section"
import { Helmet } from "react-helmet"

const AboutUsPage = props => {

  return (
    <Layout>
      <SEO
        title="О нас"
        keywords={["О компании Империя студия мебели", "Информация о студии мебели Империя", "ООО Империя студия мебели", "Информация о компании Империя студия мебели", "О студии мебели Империя"]}
        description="О нас - информация о компании Империя студия мебели"
      />
      <Helmet>
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
      </Helmet>
      <Features />
      <h2 className="text-center mt-5 mb-0 pt-5 bg-white">Преимущества работы с нами</h2>
      <Advantages />
      <VirtualTourSection />
      <h2 className="text-center mt-5 mb-0 pt-5 bg-white">Наши партнёры</h2>
      <Container fluid className="px-0 bg-white">
        <Container fluid className="partners-container bg-white px-0">
          <Row>
            <Col md={12} className="align-self-center">
              <Carousel />
            </Col>
          </Row>
        </Container>


      </Container>
    </Layout >
  )
}

export default AboutUsPage

