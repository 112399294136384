import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { graphql, StaticQuery } from "gatsby"
import Button from 'react-bootstrap/Button'
import BackgroundImage from 'gatsby-background-image'

const VirtualTourSection = () => (
    <StaticQuery query={graphql`
  query {
    showroomImage: file(relativePath: { eq: "showroom-virtual.jpg" }) {
      childImageSharp {
        fluid(quality: 85, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`}
        render={data => {
            const bgShowroomImage = data.showroomImage.childImageSharp.fluid
            return (
                <>
                    <Container fluid className="mx-auto px-0 justify-content-center align-items-center">
                        <Row className="justify-content-center align-items-center">
                            <BackgroundImage fluid={bgShowroomImage} style={{ width: "100%", height: "70vh", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", }} className="justify-content-center align-items-center">
                                <div className="mx-auto border-0 justify-content-center align-items-center">
                                    <h2 className="text-white h4 text-center">Смотреть 3D тур</h2>
                                    <Button disabled variant="outline-light" className="mx-auto d-block">Скоро</Button>
                                </div>
                            </BackgroundImage>
                        </Row>
                    </Container>
                </>
            )
        }}
    />
)

export default VirtualTourSection

