import React from "react"
import "./adv-section.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCheck, faThumbsUp, faHome, faMoneyCheckAlt } from "@fortawesome/free-solid-svg-icons"
import bankLogo from "../../images/svg-icon-bank-zero.svg"

export default function catalogAdvantages() {
    return (
        <Container fluid className="pb-5">
            <Row className="my-5">
                <Col xl={3} lg={6} md={6} className="p-5 justify-content-center align-items-center text-center">
                    <FontAwesomeIcon
                        icon={faThumbsUp}
                        style={{
                            color: "#000000CC",
                        }}
                        className="fa-3x"
                    />
                    <h3 className="font-weight-normal mt-3 h5">Используем материалы и фурнитуру только <strong>от лидирующих производителей</strong> на рынке мебели.</h3>
                </Col>
                <Col xl={3} lg={6} md={6} className="p-5 justify-content-center align-items-center text-center">
                    <FontAwesomeIcon
                        icon={faUserCheck}
                        style={{
                            color: "#000000CC",
                        }}
                        className="fa-3x"
                    />
                    <h3 className="font-weight-normal  mt-3 h5">Создаем мебель <strong>по&nbsp;индивидуальным проектам</strong>, сочетая
            эстетику и&nbsp;функционал, эргономику и&nbsp;домашний уют.</h3>
                </Col>
                <Col xl={3} lg={6} md={6} className="p-5 justify-content-center align-items-center text-center">
                    <FontAwesomeIcon
                        icon={faHome}
                        style={{
                            color: "#000000CC",
                        }}
                        className="fa-3x"
                    />
                    <h3 className="font-weight-normal  mt-3 h5">В дополнение к Вашему проекту в наших салонах вы найдете <strong>широкий ассортимент декора для дома</strong> (свет, зеркала, статуэтки, постеры и многое другое).</h3>
                </Col>
                <Col xl={3} lg={6} md={6} className="p-5 justify-content-center align-items-center text-center">
                    <img className="mb-0" src={bankLogo} alt="Беспроцентная рассрочка" />
                    <h3 className="font-weight-normal  mt-3 h5">У нас вы можете оформить заказ с <strong>беспроцентной рассрочкой</strong>. Подробности уточняйте у менеджеров.</h3>
                </Col>
            </Row>
        </Container>
    )
}
