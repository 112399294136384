import React, { Component } from "react";
import Slider from "react-slick";
import { Image } from "../image"

export default class Responsive extends Component {
    render() {
        var settings = {
            dots: false,
            infinite: true,
            slidesToShow: 4,
            pauseOnHover: false,
            autoplay: true,
            speed: 4000,
            autoplaySpeed: 4000,
            swipeToSlide: false,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        pauseOnHover: false,
                        infinite: true,
                        dots: false,
                        autoplay: true,
                        speed: 4000,
                        autoplaySpeed: 4000,
                        swipeToSlide: false,
                        cssEase: "linear",
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        pauseOnHover: false,
                        infinite: true,
                        dots: false,
                        autoplay: true,
                        speed: 4000,
                        autoplaySpeed: 4000,
                        swipeToSlide: false,
                        cssEase: "linear",
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        pauseOnHover: false,
                        infinite: true,
                        dots: false,
                        autoplay: true,
                        speed: 4000,
                        autoplaySpeed: 4000,
                        swipeToSlide: false,
                        cssEase: "linear",
                    }
                }
            ]
        };
        return (
            <div>
                <Slider {...settings}>
                    <div><Image src={1} /></div>
                    <div><Image src={2} /></div>
                    <div><Image src={3} /></div>
                    <div><Image src={4} /></div>
                    <div><Image src={5} /></div>
                    <div><Image src={6} /></div>
                    <div><Image src={7} /></div>
                    <div><Image src={8} /></div>
                    <div><Image src={9} /></div>
                    <div><Image src={10} /></div>
                    <div><Image /></div>
                </Slider>
            </div>
        );
    }
}